// extracted by mini-css-extract-plugin
export var blueContainer = "styles-module--blue-container--stCgz";
export var supportWrapper = "styles-module--support-wrapper--Rg8HM";
export var tierName = "styles-module--tier-name--bNNXH";
export var tierImage = "styles-module--tier-image--sOvJH";
export var tierCost = "styles-module--tier-cost--9cDeT";
export var tierCostContainer = "styles-module--tier-cost-container--UEt+N";
export var tierDescription = "styles-module--tier-description--3rxZf";
export var tierReward = "styles-module--tier-reward--9GGwk";
export var tierRewardContainer = "styles-module--tier-reward-container--aeKJ7";
export var tierIndex = "styles-module--tier-index--1sjZq";
export var tierNav = "styles-module--tier-nav--jX9yA";
export var tierNavBut = "styles-module--tier-nav-but--XiOmB";
export var tierPrev = "styles-module--tier-prev--0eqoF";
export var tierNext = "styles-module--tier-next--ILucY";
export var tierButton = "styles-module--tier-button--uvF6u";
export var pbCorrection = "styles-module--pb-correction--YGRpK";
export var pbCorrectionBut = "styles-module--pb-correction-but--m9aXa";