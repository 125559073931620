import React from 'react'

// Components
import PageTemplate from '../components/templates/PageTemplate'
import SupportTemplate from '../components/templates/Support'

const SupportPage = () => {
  return (
    <PageTemplate>
      <SupportTemplate />
    </PageTemplate>
  )
}

export default SupportPage
