import React, { useContext, useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { Helmet } from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'

// Components
import DilanButton from '../../atoms/DilanButton'

// Config
import { support } from '../../../config/localization'

// Context
import LocalizationContext from '../../../context/Localization/Localization.context'

// Model
import { TierModel } from '../../../models'

// graphQL
import { getGraphTiers } from '../../../graphQL'

// Utils
import { getFont } from '../../../utils/utils'

// Style
import {
  pbCorrection,
  pbCorrectionBut,
  blueContainer,
  supportWrapper,
  tierNav,
  tierNavBut,
  tierPrev,
  tierNext,
  tierName,
  tierCost,
  tierIndex,
  tierImage,
  tierButton,
  tierReward,
  tierDescription,
  tierCostContainer,
  tierRewardContainer,
} from './styles.module.scss'

const SupportTemplate: React.FC = () => {
  const { language } = useContext(LocalizationContext)

  const allTiers = getGraphTiers()

  const [localization, setLocalization] = useState(support.Default)
  const [tier, setTier] = useState<null | TierModel>(null)

  useEffect(() => {
    const newLocalization = support[language]
    if (typeof newLocalization !== 'undefined') {
      setLocalization(newLocalization)
    }
  }, [language])

  useEffect(() => {
    if (tier?.rewards[0].ENG === 'Access to our Discord') {
      tier.rewards.reverse()
    }
  }, [tier])

  useEffect(() => {
    const search = new URLSearchParams(location.search)
    const tierID = Number(search.get('tier'))
    const sessionTier = allTiers.find(({ id }) => Number(id) === Number(tierID))
    if (sessionTier) {
      setTier(sessionTier)
    } else {
      setTier(allTiers[0])
    }
  }, [])

  return (
    <>
      <Helmet>
        <title>
          {language === 'ESP'
            ? `Nivel ${tier?.name[language]} - Apóyanos`
            : `${tier?.name[language]} Tier - Support Us`}
          - Dilan
          {language === 'ESP'
            ? ' las crónicas de Covak'
            : ' The chronicles of Covak'}
        </title>
        <meta
          name="description"
          content={
            language === 'ESP'
              ? 'Mantente al tanto de Dilan y tus beneficios de Patreon.'
              : 'Keep track of Dilan and your Patreon benefits.'
          }
        />
        <meta
          name="keywords"
          content={
            language == 'ESP'
              ? 'Dilan Covak, webcomic, comic, comic gratis, fantasia, magia, espadas, peleas, Dilan, blanco y negro, homepage, Patreon, Vaki, apoyo'
              : 'Dilan Covak, webcomic, comic, free comic, fantasy, magic, swords, fights, Dilan, black and white, homepage, Patreon, Vaki, support'
          }
        />
        <meta name="author" content="Daniel Penagos" />
        <meta name="copyright" content="Daniel Penagos" />
      </Helmet>
      <div className={`block-wrapper ${blueContainer}`}>
        <div className={`container ${supportWrapper}`}>
          <div>
            <h1 className={`${tierName} ${getFont('font-patua')}`}>
              {tier?.name[language]}
            </h1>
            <div className={`${tierDescription} ${getFont('font-lato')}`}>
              {tier?.description[language]}
            </div>
            <div className={tierRewardContainer}>
              {tier?.rewards.map((rew, index) => (
                <div
                  key={`reward_${tier.name}_${rew.ENG}`}
                  className={`${tierReward} ${getFont(
                    'font-lato',
                    pbCorrection
                  )}`}
                >
                  <span className={`${tierIndex} ${getFont('font-lato')}`}>
                    {tier?.rewards.length - index}
                  </span>
                  <br />
                  {rew[language]}
                </div>
              ))}
            </div>
            <div className={`${tierCostContainer} ${getFont('font-lato')}`}>
              <p>
                <span className={tierCost}>${tier?.cost}</span>/mo{' '}
              </p>
              <a href={tier?.vaki} rel="noreferrer" target="_blank">
                <DilanButton
                  type={'primary'}
                  className={`${tierButton} ${getFont(
                    'font-lato',
                    pbCorrectionBut
                  )}`}
                  handleClick={() => null}
                >
                  {localization.vaki}
                </DilanButton>
              </a>
              <a href={tier?.patreon} rel="noreferrer" target="_blank">
                <DilanButton
                  type={'primary'}
                  className={`${tierButton} ${getFont(
                    'font-lato',
                    pbCorrectionBut
                  )}`}
                  handleClick={() => null}
                >
                  {localization.patreon}
                </DilanButton>
              </a>
            </div>
          </div>
          <div>
            <img
              className={tierImage}
              src={`https://dilancovak.com/.img/emblems/${tier?.emblem}`}
            />
            <div className={tierNav}>
              {tier?.prev && (
                <FontAwesomeIcon
                  className={`${tierNavBut} ${tierPrev}`}
                  icon={faChevronLeft}
                  onClick={() => {
                    setTier(
                      allTiers.find(
                        ({ id }) => Number(id) === Number(tier.prev)
                      ) || null
                    )
                    navigate(`?tier=${tier.prev}`)
                  }}
                />
              )}
              {tier?.next && (
                <FontAwesomeIcon
                  className={`${tierNavBut} ${tierNext}`}
                  icon={faChevronRight}
                  onClick={() => {
                    setTier(
                      allTiers.find(
                        ({ id }) => Number(id) === Number(tier.next)
                      ) || null
                    )
                    navigate(`?tier=${tier.next}`)
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SupportTemplate
